export const svgCustom = (
          nameSvg:'searchengin'|'rectangle-list'|'file-lines'|'monkey_and_hands'|'dashboard'|'repair'
          |'cctv'|'cctv-2',
          colorSvg?:string, widthSvg?:number, heightSvg?:number, colorSvg2?:string) => {
  if (nameSvg == 'searchengin'){
      return (
          <svg xmlns="http://www.w3.org/2000/svg" 
                  width={widthSvg}
                  viewBox="0 0 460 512">
              <path fill={colorSvg}
                  d="M220.6 130.3l-67.2 28.2V43.2L98.7 233.5l54.7-24.2v130.3l67.2-209.3zm-83.2-96.7l-1.3 4.7-15.2 52.9C80.6 106.7 52 145.8 52 191.5c0 52.3 34.3 95.9 83.4 105.5v53.6C57.5 340.1 0 272.4 0 191.6c0-80.5 59.8-147.2 137.4-158zm311.4 447.2c-11.2 11.2-23.1 12.3-28.6 10.5-5.4-1.8-27.1-19.9-60.4-44.4-33.3-24.6-33.6-35.7-43-56.7-9.4-20.9-30.4-42.6-57.5-52.4l-9.7-14.7c-24.7 16.9-53 26.9-81.3 28.7l2.1-6.6 15.9-49.5c46.5-11.9 80.9-54 80.9-104.2 0-54.5-38.4-102.1-96-107.1V32.3C254.4 37.4 320 106.8 320 191.6c0 33.6-11.2 64.7-29 90.4l14.6 9.6c9.8 27.1 31.5 48 52.4 57.4s32.2 9.7 56.8 43c24.6 33.2 42.7 54.9 44.5 60.3s.7 17.3-10.5 28.5zm-9.9-17.9c0-4.4-3.6-8-8-8s-8 3.6-8 8 3.6 8 8 8 8-3.6 8-8z"/>
          </svg>
      )
  }
  else if (nameSvg == 'rectangle-list'){
      return (
          <svg xmlns="http://www.w3.org/2000/svg" 
                  width={widthSvg}
                  viewBox="0 0 576 512">
              <path fill={colorSvg}  
                  d="M0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM128 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm32-128a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224z"/>
          </svg>
          // <svg xmlns="http://www.w3.org/2000/svg" 
          //         width={widthSvg}
          //         viewBox="0 0 512 512">
          //     <path fill={colorSvg} 
          //         d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
          // </svg>
      )
  }
  else if (nameSvg == 'file-lines'){
      return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"
                  width={widthSvg}>
              <path fill={colorSvg}  
                  d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
          </svg>
      )
  }
  else if (nameSvg == 'monkey_and_hands'){
      return (
          <svg xmlns="http://www.w3.org/2000/svg" style = {{display:'none'}}>
              <symbol xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 64 64" id="monkey-hands">
                  <path fill="#89664C" d="M9.4,32.5L2.1,61.9H14c-1.6-7.7,4-21,4-21L9.4,32.5z"/><path fill="#FFD6BB" d="M15.8,24.8c0,0,4.9-4.5,9.5-3.9c2.3,0.3-7.1,7.6-7.1,7.6s9.7-8.2,11.7-5.6c1.8,2.3-8.9,9.8-8.9,9.8
                  s10-8.1,9.6-4.6c-0.3,3.8-7.9,12.8-12.5,13.8C11.5,43.2,6.3,39,9.8,24.4C11.6,17,13.3,25.2,15.8,24.8"/><path fill="#89664C" d="M54.8,32.5l7.3,29.4H50.2c1.6-7.7-4-21-4-21L54.8,32.5z"/><path fill="#FFD6BB" d="M48.4,24.8c0,0-4.9-4.5-9.5-3.9c-2.3,0.3,7.1,7.6,7.1,7.6s-9.7-8.2-11.7-5.6c-1.8,2.3,8.9,9.8,8.9,9.8
                  s-10-8.1-9.7-4.6c0.4,3.8,8,12.8,12.6,13.8c6.6,1.3,11.8-2.9,8.3-17.5C52.6,17,50.9,25.2,48.4,24.8"/>
              </symbol>
              <symbol xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 64 64" id="monkey">
                  <ellipse cx="53.7" cy="33" rx="8.3" ry="8.2" fill="#89664c"/><ellipse cx="53.7" cy="33" rx="5.4" ry="5.4" fill="#ffc5d3"/><ellipse cx="10.2" cy="33" rx="8.2" ry="8.2" fill="#89664c"/><ellipse cx="10.2" cy="33" rx="5.4" ry="5.4" fill="#ffc5d3"/><g fill="#89664c"><path d="m43.4 10.8c1.1-.6 1.9-.9 1.9-.9-3.2-1.1-6-1.8-8.5-2.1 1.3-1 2.1-1.3 2.1-1.3-20.4-2.9-30.1 9-30.1 19.5h46.4c-.7-7.4-4.8-12.4-11.8-15.2"/><path d="m55.3 27.6c0-9.7-10.4-17.6-23.3-17.6s-23.3 7.9-23.3 17.6c0 2.3.6 4.4 1.6 6.4-1 2-1.6 4.2-1.6 6.4 0 9.7 10.4 17.6 23.3 17.6s23.3-7.9 23.3-17.6c0-2.3-.6-4.4-1.6-6.4 1-2 1.6-4.2 1.6-6.4"/></g><path d="m52 28.2c0-16.9-20-6.1-20-6.1s-20-10.8-20 6.1c0 4.7 2.9 9 7.5 11.7-1.3 1.7-2.1 3.6-2.1 5.7 0 6.1 6.6 11 14.7 11s14.7-4.9 14.7-11c0-2.1-.8-4-2.1-5.7 4.4-2.7 7.3-7 7.3-11.7" fill="#e0ac7e"/><g fill="#3b302a"><path d="m35.1 38.7c0 1.1-.4 2.1-1 2.1-.6 0-1-.9-1-2.1 0-1.1.4-2.1 1-2.1.6.1 1 1 1 2.1"/><path d="m30.9 38.7c0 1.1-.4 2.1-1 2.1-.6 0-1-.9-1-2.1 0-1.1.4-2.1 1-2.1.5.1 1 1 1 2.1"/><ellipse cx="40.7" cy="31.7" rx="3.5" ry="4.5"/><ellipse cx="23.3" cy="31.7" rx="3.5" ry="4.5"/></g>
              </symbol>
          </svg>
      )
  }
  else if (nameSvg == 'dashboard'){
    return (
          <svg fill="#000000" width={widthSvg??18} height={widthSvg??18} viewBox="0 0 24 24" id="dashboard" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
                <path id="secondary" fill={'rgb(44, 169, 188)'} d="M22,4V7a2,2,0,0,1-2,2H15a2,2,0,0,1-2-2V4a2,2,0,0,1,2-2h5A2,2,0,0,1,22,4ZM9,15H4a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V17A2,2,0,0,0,9,15Z"></path>
                <path id="primary" fill={'rgb(0, 0, 0)'} d="M11,4v7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H9A2,2,0,0,1,11,4Zm9,7H15a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2h5a2,2,0,0,0,2-2V13A2,2,0,0,0,20,11Z"></path>
          </svg>
    )
  }
  else if (nameSvg == 'repair'){
    return (
      <svg width="18" height="18" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 0a4 4 0 00-4 4v8a4 4 0 004 4h8a4 4 0 004-4V4a4 4 0 00-4-4H4zm7.293 3.292a3 3 0 00-4.001 4.001l-4 4a1 1 0 101.415 1.414l4-4a3 3 0 004.001-4.001l-2 2.001a1 1 0 11-1.415-1.414l2-2z" fill="navy"/>
      </svg>
    )
  }
  else if (nameSvg == 'cctv'){
    return (
    
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 512 512"  enableBackground="0 0 512 512"
        width={widthSvg}>
      <g>
        <path fill="#C7CFE2" d="M503.129,291.485h-26.614c-5.804,0-10.947,12.562-14.178,31.96
          c-2.098,12.592-13.095,21.779-25.856,22.134l-288.201,8.005v35.485l288.201,8.005c12.761,0.355,23.758,9.541,25.856,22.134
          c3.232,19.398,8.374,31.96,14.178,31.96h26.614c4.899,0,8.871-3.972,8.871-8.871v-141.94
          C512,295.457,508.028,291.485,503.129,291.485z"/>
        <rect x="148.283" y="300.358" fill="#C7CFE2" width="53.227" height="44.356"/>
        <path fill="#C7CFE2" d="M0.112,89.861L14.51,247.934c0.416,4.569,4.247,8.066,8.835,8.066h303.162
          c4.589,0,8.42-3.5,8.835-8.069l14.343-158.079c1.414-15.583-10.857-29.019-26.505-29.019H26.616
          C10.965,60.833-1.308,74.274,0.112,89.861z"/>
      </g>
      <path fill="#E4EAF6" d="M290.253,309.227H59.601c-9.799,0-17.742-7.943-17.742-17.742V114.06
        c0-9.799,7.943-17.742,17.742-17.742h230.652c9.799,0,17.742,7.943,17.742,17.742v177.425
        C307.996,301.284,300.052,309.227,290.253,309.227z"/>
      <path fill="#5B5D6E" d="M245.863,256H103.992c-4.899,0-8.871-3.972-8.871-8.871v-88.712c0-4.899,3.972-8.871,8.871-8.871
        h141.87c4.899,0,8.871,3.972,8.871,8.871v88.712C254.734,252.029,250.762,256,245.863,256z"/>
      <circle fill="#464655" cx="174.93" cy="202.775" r="44.356"/>
      <path fill="#E4EAF6" d="M476.515,291.485c-9.798,0-17.742,35.746-17.742,79.841c0,44.094,7.944,79.841,17.742,79.841h26.614
        c4.899,0,8.871-3.972,8.871-8.871v-141.94c0-4.899-3.972-8.871-8.871-8.871H476.515z"/>
      <circle fill="#5B5D6E" cx="174.93" cy="202.775" r="26.614"/>
      <circle fill="#FFFFFF" cx="174.93" cy="202.775" r="8.871"/>
      <circle fill="#D7DEED" cx="201.51" cy="176.161" r="17.742"/>
      <g>
        <path fill="#FFFFFF" d="M199.86,193.735c-2.687-7.405-8.519-13.226-15.927-15.905
          C184.734,186.283,191.403,192.944,199.86,193.735z"/>
        <path fill="#FFFFFF" d="M201.507,158.416c-3.785,0-7.276,1.205-10.155,3.225c11.243,4.494,20.183,13.418,24.691,24.652
          c2.01-2.876,3.207-6.36,3.207-10.134C219.249,166.359,211.305,158.416,201.507,158.416z"/>
      </g>
      <path fill="#E4EAF6" d="M174.893,415.682L174.893,415.682c-19.598,0-35.485-15.887-35.485-35.485v-35.485
        c0-4.899,3.972-8.871,8.871-8.871h53.227c4.899,0,8.871,3.972,8.871,8.871v35.485C210.378,399.795,194.491,415.682,174.893,415.682z
        "/>
      </svg>

    )
  }
  else if (nameSvg == 'cctv-2'){
    return (
      <svg version="1.1" width={130} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280.606 280.606" enableBackground="0 0 280.606 280.606" transform="matrix(-1,0,0,1,0,0)">
        <g>
          <path fill="#FFFFFF" d="m278.161,191.032l-149.199-149.199c-3.89-3.89-10.253-3.89-14.143,0l-55.861,55.861c-3.89,3.89-3.89,10.411 0,14.302l14.098,14.256h-40.056v-23.317c0-5.5-4.44-9.683-9.94-9.683h-13c-5.5,0-10.06,4.183-10.06,9.683v79c0,5.5 4.56,10.317 10.06,10.317h13c5.5,0 9.94-4.817 9.94-10.317v-22.683h73.056l78.767,78.607c3.89,3.891 11.097,4.979 16.016,2.52l75.449-37.764c4.919-2.459 5.763-7.693 1.873-11.583zm-162.104-127.81c3.223-3.222 8.445-3.222 11.668-7.10543e-15 3.222,3.223 3.222,8.445 0,11.667-3.223,3.223-8.445,3.223-11.668,0.001-3.222-3.222-3.222-8.445 1.42109e-14-11.668zm53.349,135.373l-94.007-94.007 11.313-11.313 94.007,94.007-11.313,11.313z"></path>
        </g>
      </svg>
    )
  }

}