import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import './AnalitikAll.scss';
import { Bars } from 'react-loader-spinner';
import { PPE_getApiSync, URL_API_PPE, getValueFromLocalStorageFunc, notify } from '../../../services/functions';
import { ToastContainer } from 'react-toastify';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const defaultCenter = {
  lat: -6.288233,
  lng: 106.826316,
};

interface Location {
  id: number;
  id_device: string;
  id_user: number;
  username:string;
  latitude: number;
  longitude: number;
  time: string;
}

// Colors for the pins
const pinColors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];

const DashboardAnalitikAll: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [showLoaderMain, setShowLoaderMain] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [icons, setIcons] = useState<{ [color: string]: google.maps.Icon | null }>({});

  const getDataLastLocation = async () => {
    let response = await PPE_getApiSync(
      `${URL_API_PPE}/v1/location/lastlocationmember`,
      null,
      'application/json',
      'GET',
      getValueFromLocalStorageFunc('IOT_TOKEN') ?? ''
    );

    if (response?.statusCode) {
      notify('error', "'API Location' " + response?.msg ?? '', 'TOP_CENTER');
      return [];
    } else if (response?.detail) {
      notify('error', "'API Location' " + (response?.detail?.[0]?.msg ?? response?.detail ?? ''), 'TOP_CENTER');
      return [];
    } else {
      return Array.isArray(response) ? response : [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setShowLoaderMain(true);
      const apiData = await getDataLastLocation();
      setLocations(apiData as Location[]);
      setShowLoaderMain(false);
    };
    fetchData();
  }, []);

  // Generate icons after Google Maps API loads
  const onLoad = () => {
    const iconMap: { [color: string]: google.maps.Icon | null } = {};
    pinColors.forEach(color => {
      iconMap[color] = {
        url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
        scaledSize: new google.maps.Size(32, 32),
      };
    });
    setIcons(iconMap);
  };

  return (
    <div className='ppe-anly'>
      {showLoaderMain && (
        <div className='ppe-anly-ppe-loader-main'>
          <Bars width='80' height='80' visible={showLoaderMain} color='#4fa94d' />
        </div>
      )}

      {!showLoaderMain && (
        <LoadScript googleMapsApiKey="AIzaSyA_JFGySQ66QzP2uILopoPZ5MbHjbTHiSY" onLoad={onLoad}>
          <GoogleMap mapContainerStyle={mapContainerStyle} zoom={8} center={defaultCenter}>
            {locations.map((location, index) => (
              <Marker
                key={location.id}
                position={{ lat: location.latitude, lng: location.longitude }}
                icon={icons[pinColors[index % pinColors.length]] || undefined}
                onClick={() => setSelectedLocation(location)}
              />
            ))}
            {selectedLocation && (
              <InfoWindow
                position={{
                  lat: selectedLocation.latitude,
                  lng: selectedLocation.longitude,
                }}
                onCloseClick={() => setSelectedLocation(null)}
              >
                <div>
                  <p><strong>Device ID:</strong> {selectedLocation.id_device}</p>
                  <p><strong>User:</strong> {selectedLocation.username}</p>
                  <p><strong>Time:</strong> {new Date(selectedLocation.time).toLocaleString()}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      )}

      <ToastContainer draggable pauseOnHover />
    </div>
  );
};

export default DashboardAnalitikAll;
