import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import './History.scss';
import { Bars } from 'react-loader-spinner';
import { PPE_getApiSync, URL_API_PPE, getValueFromLocalStorageFunc, notify } from '../../../services/functions';
import { ToastContainer } from 'react-toastify';

const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

const defaultCenter = {
  lat: -6.288233,
  lng: 106.826316,
};

interface DeviceData {
  id: number;
  id_device: string;
  latitude: number;
  longitude: number;
  time: string;
}

interface Device {
  id_device: string;
  name: string;
  device_data: DeviceData[];
}

const pinColors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];

const History: React.FC = () => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [showLoaderMain, setShowLoaderMain] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState<{ location: DeviceData, name: string } | null>(null);
  const [icons, setIcons] = useState<{ [color: string]: google.maps.Icon | null }>({});

  const getDataHistory = async () => {
    let response = await PPE_getApiSync(
      `${URL_API_PPE}/v1/location/historymember`,
      null,
      'application/json',
      'GET',
      getValueFromLocalStorageFunc('IOT_TOKEN') ?? ''
    );

    if (response?.statusCode) {
      notify('error', "'API Location' " + response?.msg ?? '', 'TOP_CENTER');
      return [];
    } else if (response?.detail) {
      notify('error', "'API Location' " + (response?.detail?.[0]?.msg ?? response?.detail ?? ''), 'TOP_CENTER');
      return [];
    } else {
      return Array.isArray(response) ? response : [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setShowLoaderMain(true);
      const apiData = await getDataHistory();
      setDevices(apiData as Device[]);
      setShowLoaderMain(false);
    };
    fetchData();
  }, []);

  // Generate icons once the Google Maps API has loaded
  const onLoad = () => {
    if (window.google) {
      const iconMap: { [color: string]: google.maps.Icon | null } = {};
      pinColors.forEach(color => {
        iconMap[color] = {
          url: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
          scaledSize: new window.google.maps.Size(32, 32),
        };
      });
      setIcons(iconMap);
    }
  };

  return (
    <div className='ppe-anly'>
      {showLoaderMain && (
        <div className='ppe-anly-ppe-loader-main'>
          <Bars width='80' height='80' visible={showLoaderMain} color='#4fa94d' />
        </div>
      )}

      {!showLoaderMain && (
        <LoadScript googleMapsApiKey="AIzaSyA_JFGySQ66QzP2uILopoPZ5MbHjbTHiSY" onLoad={onLoad}>
          <GoogleMap mapContainerStyle={mapContainerStyle} zoom={8} center={defaultCenter}>
            {devices.map((device, deviceIndex) => (
              device.device_data.map((location) => (
                <Marker
                  key={location.id}
                  position={{ lat: location.latitude, lng: location.longitude }}
                  icon={icons[pinColors[deviceIndex % pinColors.length]] || undefined}
                  onClick={() => setSelectedLocation({ location, name: device.name })}
                />
              ))
            ))}
            {selectedLocation && (
              <InfoWindow
                position={{
                  lat: selectedLocation.location.latitude,
                  lng: selectedLocation.location.longitude,
                }}
                onCloseClick={() => setSelectedLocation(null)}
              >
                <div>
                  <p><strong>Device ID:</strong> {selectedLocation.location.id_device}</p>
                  <p><strong>User:</strong> {selectedLocation.name}</p>
                  <p><strong>Time:</strong> {new Date(selectedLocation.location.time).toLocaleString()}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      )}

      <ToastContainer draggable pauseOnHover />
    </div>
  );
};

export default History;
