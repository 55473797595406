import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../../pages/login';
import Main from '../../pages/main';
import ListUsers from '../../components/list/Users';
import ListCameras from '../../components/list/Cameras';
import ListFeatures from '../../components/list/Features';
import ListResults from '../../components/list/Results';
import ListSettings from '../../components/list/Settings';

import DashboardCameraOnline from '../../components/dashboard/CameraOnline';
import Task from '../../components/dashboard/Task';
import DashboardAnalitikAll from '../../components/dashboard/AnalitikAll';
import History from '../../components/dashboard/History';
import LiveView from '../../components/dashboard/LiveView';
import Recordings from '../../components/dashboard/Recordings';
import ROI from '../../components/others/ROI';
import Events from '../../components/dashboard/Events';
import ListSites from '../../components/list/Sites';
import ListPlaces from '../../components/list/Places';

const Route_custom = () => (
  <Routes>
    <Route path="/login" element={<Login />} />

    <Route path="/" element={<Navigate to="/login" />} />

    <Route path="list" element={<Main />}>
      <Route path="users" element={<ListUsers />} />
      <Route path="sites" element={<ListSites />} />
      <Route path="places" element={<ListPlaces />} />
      <Route path="cameras" element={<ListCameras />} />
      <Route path="features" element={<ListFeatures />} />
      <Route path="results" element={<ListResults />} />
      <Route path="settings" element={<ListSettings />} />
    </Route>

    <Route path="other" element={<Main />}>
      <Route path="roi" element={<ROI />} />
    </Route>

    <Route path="dashboard" element={<Main />}>
      <Route index element={<Navigate to="analitik" />} />
      <Route path="analitik" element={<DashboardAnalitikAll />} />
      <Route path="history" element={<History />} />

      <Route path="monitoring">
        <Route index element={<Navigate to="camera-online" />} />
        <Route path="camera-online" element={<DashboardCameraOnline />} />
        <Route path="task" element={<Task />} />
        <Route path="live-view" element={<LiveView />} />
        <Route path="recordings" element={<Recordings />} />
        <Route path="events" element={<Events />} />
      </Route>
    </Route>

    <Route path="/*" element={<Navigate to="/login" />} />
  </Routes>
);

export default Route_custom;
